// export const BASE_URL = "https://tradehall.gaincue.com/api/v1";
// export const BASE_URL = 'http://127.0.0.1:3000/api/v1'
// export const BASE_URL = 'https://ml-api.tradehall.co/api/v1'
export const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const convertNumberFloatZero = (number) => {
  if (!isNumeric(number)) {
    return 'NaN'
  }
  let num = Number(number)

  var numberFloat = num.toFixed(3)
  var splitNumber = numberFloat.split('.')
  var cNumberFloat = num.toFixed(2)
  var cNsplitNumber = cNumberFloat.split('.')
  var lastChar = splitNumber[1].substr(splitNumber[1].length - 1)
  if (lastChar > 0 && lastChar < 5) {
    cNsplitNumber[1]--
  }
  return Number(splitNumber[0]).toLocaleString('en').concat('.').concat(cNsplitNumber[1])
}

const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const titleCase = (string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join('')
}

export const bankList = [
  {
    name: 'CIMB Bank',
    code: 'CIMB.MY',
    country: 'Malaysia'
  },
  {
    name: 'OCBC Bank (MALAYSIA)',
    code: 'OCBC.MY',
    country: 'Malaysia'
  },
  {
    name: 'Hong Leong Bank',
    code: 'HLB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Maybank',
    code: 'MBB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Public Bank',
    code: 'PBB.MY',
    country: 'Malaysia'
  },
  {
    name: 'UOB Bank',
    code: 'UOB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Ambank',
    code: 'ARB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Affin Bank',
    code: 'AFB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Alliance Bank',
    code: 'ALB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Standard Chartered Bank',
    code: 'SCB.MY',
    country: 'Malaysia'
  },
  {
    name: 'RHB Bank',
    code: 'RHB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Bank Islam Malaysia',
    code: 'BIMB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Bank Rakyat Malaysia',
    code: 'BKR.MY',
    country: 'Malaysia'
  },
  {
    name: 'Bank Simpanan Nasional Malaysia',
    code: 'BSN.MY',
    country: 'Malaysia'
  },
  {
    name: 'HSBC Bank Malaysia',
    code: 'HSBC.MY',
    country: 'Malaysia'
  },
  //Indonesia
  {
    name: 'Bank Rakyat Indonesia',
    code: 'BRI.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Negara Indonesia',
    code: 'BNI.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Central Asiaa',
    code: 'BCA.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Mandiri',
    code: 'MDR.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Bukopin',
    code: 'BBKP.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Commonwealth',
    code: 'BCW.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Danamon',
    code: 'BDNM.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Mega',
    code: 'BM.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Maspion',
    code: 'BMP.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Mestika',
    code: 'BMTK.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Panin',
    code: 'BPNN.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Sumut',
    code: 'BSMT.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Sinar Mas',
    code: 'BSNM.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Btpn',
    code: 'BTPN.ID',
    country: 'Indonesia'
  },
  {
    name: 'CIMB Niaga',
    code: 'CIMBN.ID',
    country: 'Indonesia'
  },
  {
    name: 'HSBC Indonesia',
    code: 'HSBC.ID',
    country: 'Indonesia'
  },
  {
    name: 'Maybank /bii',
    code: 'MBBI.ID',
    country: 'Indonesia'
  },
  {
    name: 'OCBC Indonesia',
    code: 'OCBC.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Artha Graha ',
    code: 'BAG.ID',
    country: 'Indonesia'
  },
  {
    name: 'Bank Permata',
    code: 'PMTB.ID',
    country: 'Indonesia'
  },
  {
    name: 'UOB Indonesia',
    code: 'UOB.ID',
    country: 'Indonesia'
  },
  //Thailand
  {
    name: 'Krung Thai Bank',
    code: 'KTB.TH ',
    country: 'Thailand'
  },
  {
    name: 'Siam Commercial Bank',
    code: 'SCB.TH ',
    country: 'Thailand'
  },
  {
    name: 'Bangkok bank',
    code: 'BBL.TH ',
    country: 'Thailand'
  },
  {
    name: 'Kasikorn Bank',
    code: 'KBANK.TH ',
    country: 'Thailand'
  },
  {
    name: 'Thai Military Bank',
    code: 'TMB.TH ',
    country: 'Thailand'
  },
  {
    name: 'Krungsri bank',
    code: 'BAY.TH ',
    country: 'Thailand'
  },
  //Vietnam
  {
    name: 'Techcombank',
    code: 'TCB.VN',
    country: 'Vietnam'
  },
  {
    name: 'Sacombank',
    code: 'SCM.VN',
    country: 'Vietnam'
  },
  {
    name: 'Vietcombank',
    code: 'VCB.VN',
    country: 'Vietnam'
  },
  {
    name: 'Asia Commercial Bank',
    code: 'ACB.VN',
    country: 'Vietnam'
  },
  {
    name: 'DongA Bank',
    code: 'DAB.VN',
    country: 'Vietnam'
  },
  {
    name: 'Vietinbank',
    code: 'VTB.VN',
    country: 'Vietnam'
  },
  {
    name: 'BIDV Bank',
    code: 'BIDV.VN',
    country: 'Vietnam'
  },
  {
    name: 'Eximbank',
    code: 'EXIM.VN',
    country: 'Vietnam'
  },
  {
    name: 'Agribank',
    code: 'VBARD.VN',
    country: 'Vietnam'
  }
]
